import { useEffect, useRef, useState } from 'react';

const useDragScroll = containerRef => {
  const [mouseDown, setMouseDown] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const handleMouseDown = e => {
      e.preventDefault();
      setMouseDown(true);
      setDragging(false);

      const containerRect = container.getBoundingClientRect();
      setStartX(e.pageX - containerRect.left);
      setScrollLeft(container.scrollLeft);
    };

    const handleMouseUp = () => {
      setMouseDown(false);
      setDragging(false);
    };

    const handleMouseMove = e => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      } else {
        setDragging(true);
      }
      const x = e.pageX - container.getBoundingClientRect().left;
      const scroll = x - startX;
      container.scrollLeft = scrollLeft - scroll;
    };

    const handleLinkClick = e => {
      if (dragging) {
        e.preventDefault();
      }
    };

    const handleOutsideClick = e => {
      if (dragging && container && !container.contains(e.target)) {
        setMouseDown(false);
        setDragging(false);
      }
    };

    container.addEventListener('mousedown', handleMouseDown, false);
    container.addEventListener('mouseup', handleMouseUp, false);
    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseleave', handleMouseUp, false);

    const links = container.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    container.addEventListener('click', handleOutsideClick);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseleave', handleMouseUp);

      links.forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });

      container.removeEventListener('click', handleOutsideClick);
    };
  }, [containerRef.current, mouseDown, dragging, startX, scrollLeft]);

  return { mouseDown, dragging };
};

export default useDragScroll;
