import React, { useRef } from 'react';

import css from '../HomePageCustom.module.css';

const HandmadeIntro = () => {

  return (      
    <section id={css['handmade-intro']}>
      <div className={css.inner}>
        {/*<div className={css['intro-top']}>
          <h2>
            More than a marketplace
            <span>A movement</span>
          </h2>
        </div>*/}
        <div className={css.container}>
          <div className={css['introblock-type1a']}>
            <div className={css['intro-1']}>
              <div className={css['text-top']}>
                <h2>
                  More than a marketplace.
                  <span>A&nbsp;movement.</span>
                </h2>
              </div>
              <div className={css.image}>
                <img src="/static/images/become-a-seller/section5-image1b.jpg" className={css.featured} />
              </div>
            </div>
            <div className={css['text-1']}>
              <h6>Why? Because people <span className={css['c-highlight3']}>really do care</span>.</h6>
              <p>In a world increasingly anonymous and consolidated, we crave meaning and authenticity.</p>
            </div>
            <div className={css['intro-2']}>
              <div className={css['text-top']}>
                <h6>We’re building a whole <span className={css['c-highlight3']}>new world for artisans.</span></h6>
                <p>Because a better world for artisans is a better world for all. </p>
              </div>
              <div className={css.image}>
                <img src="/static/images/become-a-seller/section3-image1b.jpg" className={css.featured} />
              </div>
            </div>
            <div className={css['text-2']}>
              <h6>
                Once in a generation, an industry experiences a <span className={css['c-highlight3']}>massive shift</span>
              </h6>
              <p>
                that disrupts the current landscape and creates a better path.
              </p>
            </div>
          </div>
        </div>
        <div className={`${css['intro-3']} ${css.container} ${css.full}`}>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image1b.jpg" />
          </div>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image3.jpg" />
          </div>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image4.jpg" />
          </div>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image5.jpg" />
          </div>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image2b.jpg" />
          </div>
          <div className={css.image}>
            <img src="/static/images/become-a-seller/section8-image6.jpg" />
          </div>
        </div>
        <div className={css.container}>
          <div className={css['text-3']}>
            <h6>
              It's happening. <span className={css['c-highlight1']}>Now</span>.
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HandmadeIntro;