import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { useConfiguration } from '../../context/configurationContext';

import { LayoutSingleColumn, NamedLink, Page, IconSpinner } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import HomePageSliders from './components/HomePageSliders';
import HomePageFeaturedArtists from './components/HomePageFeaturedArtists';
import HomepageCategoryGrid from './components/HomepageCategoryGrid';
import FeaturedArtist from './components/FeaturedArtist';
import HandmadeIntro from './components/HandmadeIntro';

import css from './HomePageCustom.module.css';

const topbannerImages = {
  main: [
    "/static/images/landing/topbanner/pos1_1.jpg",
    "/static/images/landing/topbanner/pos1_2.jpg",
    "/static/images/landing/topbanner/pos1_4.jpg",
  ],
  image2: [
    "/static/images/landing/topbanner/pos2_1a.jpg",
    "/static/images/landing/topbanner/pos2_2a.jpg",
    "/static/images/landing/topbanner/pos2_3a.jpg",
  ],
  image3: [
    "https://images1.novica.net/pictures/7/p258745_2_400.jpg",
    "https://images1.novica.net/pictures/10/p358925_2_400.jpg",
    "https://images1.novica.net/pictures/4/p363694_2_400.jpg",
    "https://images1.novica.net/pictures/15/p369452_2_400.jpg",
    "https://images1.novica.net/pictures/27/p399740_2_400.jpg",
    "https://images1.novica.net/pictures/7/p403016_2_400.jpg",
    "https://images1.novica.net/pictures/7/p410616_2_400.jpg",
    "https://images1.novica.net/pictures/7/p417519_2_400.jpg",
    "https://images1.novica.net/pictures/7/p417539_2_400.jpg",
    "https://images1.novica.net/pictures/46/p437338_2_400.jpg",
    "https://images1.novica.net/pictures/45/p444405_2_400.jpg",
    "https://images1.novica.net/pictures/46/p445937_2_400.jpg",
    "https://images1.novica.net/pictures/46/p448111_2_400.jpg",
  ]
};

const HomePageSection = () => {
  const intl = useIntl();
  const randomMainBanner = _.isArray(topbannerImages.main) ? _.sample(topbannerImages.main) : "/static/images/landing/topbanner/pos1_1.jpg";
  const randomBanner2 = _.isArray(topbannerImages.image2) ? _.sample(topbannerImages.image2) : "/static/images/landing/topbanner/pos2_1a.jpg";
  const randomBanner3 = _.isArray(topbannerImages.image3) ? _.sample(topbannerImages.image3) : "https://images1.novica.net/pictures/7/p258745_2_400.jpg";
  return (
    <div className={css.handmadecontent}>
      <section id={css.topbanner}>
        <div className={css.container}>
          <div className={css['banner-row1']}>
            <div className={css.text}>
              <h1>A&nbsp;marketplace <span>like&nbsp;no&nbsp;other</span></h1>
              <p>
                <Link to="/#trending-now"
                  className={`${css.button} ${css['button-highlight1']} ${css['button-animate']} ${css['button-animate-dark']}`}
                >
                  {intl.formatMessage({ id: 'HomePageCustom.shopNow' })}
                  <span className={`${css.icon} material-symbols-outlined`}>
                    arrow_right_alt
                  </span>
                </Link>
                {/*<NamedLink
                  className={`${css.button} ${css['button-highlight1']} ${css['button-animate']} ${css['button-animate-dark']}`}
                  name="SearchPage"
                >
                  {intl.formatMessage({ id: 'HomePageCustom.shopNow' })}
                  <span className={`${css.icon} material-symbols-outlined`}>
                    arrow_right_alt
                  </span>
                </NamedLink>*/}
              </p>
            </div>
            <div className={css.image1}>
              <img src={randomMainBanner} loading="lazy" />
            </div>
          </div>
          <div className={css['banner-row2']}>
            <div className={css.image2}>
              <img src={randomBanner2} loading="lazy" />
              {/*<img src="/static/images/landing/topbanner/pos2_1.jpg" loading="lazy" />
              <img src="/static/images/landing/topbanner/pos2_2.jpg" loading="lazy" />
              <img src="/static/images/landing/topbanner/pos2_3.jpg" loading="lazy" />
              <img src="/static/images/landing/topbanner/pos2_4.jpg" loading="lazy" />
              <img src="/static/images/landing/topbanner/pos2_5.jpg" loading="lazy" />*/}
            </div>
            <div className={css.row2}>
              <div className={css.image3}>
                <div className={css.image}>
                  <img src={randomBanner3} loading="lazy" />
                  {/*<img src="https://images1.novica.net/pictures/10/p307663_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/10/p358925_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/4/p363694_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/15/p369452_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/27/p399740_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/7/p403016_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/7/p410616_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/7/p417519_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/7/p417539_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/46/p437338_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/45/p444405_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/46/p445937_2_400.jpg" loading="lazy" />
                  <img src="https://images1.novica.net/pictures/46/p448111_2_400.jpg" loading="lazy" />*/}
                </div>
              </div>
              <div className={css.text}>
                <p><img src="/static/images/landing/arrow.png" className={css.arrow} /></p>
                <p className={css['welcome-to-handmade-movement']}>Welcome&nbsp;to&nbsp;the <span className={css['underlined-highlight1']}>Handmade&nbsp;Movement</span></p>
                <p>
                  <Link to="/about-us/the-movement/" className={`${css.button} ${css['button-offwhite']} ${css['button-animate']} ${css['button-animate-highlight1']}`}>
                    The Movement
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className={css['text-row']}>
            <p className={css['welcome-to-handmade-movement']}>Welcome&nbsp;to&nbsp;the <span className={css['underlined-highlight1']}>Handmade&nbsp;Movement</span></p>
            <p>
              <Link to="/about-us/the-movement/" className={`${css.button} ${css['button-offwhite']} ${css['button-animate']} ${css['button-animate-highlight1']}`}>
                {intl.formatMessage({ id: 'HomePageCustom.ourMission' })}
                <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
              </Link>
            </p>
          </div>
          <div className={css.background}>
            <div className={css.inner}>
              <img src="/static/images/landing/logo/main.png" className={css['banner-logo']} />
            </div>
          </div>
        </div>
      </section>

      <HomepageCategoryGrid id="trending-now" scrollTarget="#trending-now" />
      
      {/*<HomePageFeaturedArtists />*/}

      {/* something for everyone */}
      <section
        id={css['ideas-block1']}
        className={`${css['layout-type7']} ${css['has-scroll']}`}
      >
        <div className={`${css.container} ${css.topcontent}`}>
          <div className={css.heading}>
            <h4 className={css.nomargin}>
              This just sold!
            </h4>
            {/*<a
              href=""
              id={css['ideas-block1-button1']}
              className={`${css.button} ${css['button-white']} ${
                css['button-animate']
              } ${css['button-animate-dark']}`}
            >
              {intl.formatMessage({ id: 'HomePageCustom.seeMore' })}
              <span
                className={`${css.icon} ${
                  css['c-highlight1']
                } material-symbols-outlined`}
              >
                arrow_right_alt
              </span>
            </a>*/}
          </div>
          <div className={css['textblock-adjwidth']}>
            <p>
              {intl.formatMessage({
                id: 'HomePageCustom.thisJustSoldDescription',
              })}
            </p>
          </div>
        </div>

        <HomePageSliders />

        {/*<div className={`${css.container} ${css.bottomcontent}`}>
          <p>
            <a
              href=""
              id={css['ideas-block1-button2']}
              className={`${css.button} ${css['button-white']} ${
                css['button-animate']
              } ${css['button-animate-dark']}`}
            >
              See More
              <span
                className={`${css.icon} ${
                  css['c-highlight1']
                } material-symbols-outlined`}
              >
                arrow_right_alt
              </span>
            </a>
          </p>
        </div>*/}
      </section>

      {/* featured artist */}
      {/*<FeaturedArtist />*/}

      {/* handmade intro */}
      <HandmadeIntro />
      
      {/*<section id={css['handmade-intro']}>
        <div className={css.inner}>
          <div className={css['intro-top']}>
            <h2>
              More than a marketplace
              <span>A movement</span>
            </h2>
          </div>
          <div className={css.container}>
            <div className={css['introblock-type1']}>
              <div className={css['intro-1']}>
                <div className={css.text}>
                  <h6><strong>Why?</strong> Because people really do <strong>care</strong>.</h6>
                  <p>In a world increasingly anonymous and consolidated,<br />we crave meaning and authenticity.</p>
                </div>
                <img src="/static/images/become-a-seller/section5-image1b.jpg" className={css.featured} />
              </div>
              <div className={css['intro-2']}>
                <img src="/static/images/landing/arrow.png" className={css.arrow} />
                <img src="/static/images/landing/arrow-right-highlight1.png" className={css['arrow-down']} />
                <div className={css.text}>
                  <h6>We’re building a whole <strong>new world for artisans</strong>.</h6>
                  <p>Because a better world for artisans is a better world for all. </p>
                </div>
                <img src="/static/images/become-a-seller/section3-image1b.jpg" className={css.featured} />
              </div>
            </div>
            <div className={css['introblock-type2']}>
              <img src="/static/images/landing/arrow.png" className={css.arrow} />
              <img src="/static/images/landing/arrow-right-highlight1.png" className={css['arrow-down']} />
              <div className={css.text}>
                <h6>Once in a generation, an industry experiences a <strong>massive shift</strong></h6>
                <p>that disrupts the current landscape and creates a better path.</p>
              </div>
              <div className={css['intro-3']}>
                <div>
                  <img src="/static/images/become-a-seller/section8-image1b.jpg" />
                </div>
                <div>
                  <img src="/static/images/become-a-seller/section8-image3.jpg" />
                </div>
                <div>
                  <img src="/static/images/become-a-seller/section8-image4.jpg" />
                </div>
                <div>
                  <img src="/static/images/become-a-seller/section8-image5.jpg" />
                </div>
                <div>
                  <img src="/static/images/become-a-seller/section8-image2b.jpg" />
                </div>
                <div>
                  <img src="/static/images/become-a-seller/section8-image6.jpg" />
                </div>
              </div>
              <h3>It's happening. <strong>Now</strong>.</h3>
            </div>
          </div>
        </div>
      </section>*/}

      {/* best of handmade */}
      {/*<section className={`${css.categoryview} ${css['layout-type4']}`}>
        <div className={css.container}>
          <div className={`${css.container} ${css.full}`}>
            <div className={css.description}>
              <h5 className={`${css.heading} ${css.nomargin}`}>
                The Best of{' '}
                <span className={css['c-highlight3']}>Handmade</span>
              </h5>
            </div>
            <div className={`${css['cat-grid']} ${css.image}`}>
              <div className={css.list}>
                <div className={css.box}>
                  <a href="" className={`${css.image} ${css['hover-large']}}`}>
                    <img
                      src="/static/images/landing/temp/sample1.jpg"
                      className={css.productpic}
                    />
                  </a>
                  <div className={css.details}>
                    <p className={css['lead-title']}>
                      <a href="">Featured Artist</a>
                    </p>
                    <p className={css.heading}>
                      <a href="">
                        <strong>Hello World!</strong>
                      </a>
                    </p>
                    <div className={css.description}>
                      <a href="">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua
                        </p>
                      </a>
                    </div>
                  </div>
                  <p className={css.actionbutton}>
                    <a
                      href=""
                      className={`${css.button} ${css['button-white']} ${
                        css['button-animate']
                      } ${css['button-animate-dark']}`}
                    >
                      Category Link
                      <span
                        className={`${css.icon} ${
                          css['c-highlight1']
                        } material-symbols-outlined`}
                      >
                        arrow_right_alt
                      </span>
                    </a>
                  </p>
                </div>
                <div className={css.box}>
                  <a href="" className={`${css.image} ${css['hover-large']}}`}>
                    <img
                      src="/static/images/landing/p317515_2_400.jpg"
                      className={css.productpic}
                    />
                  </a>
                  <div className={css.details}>
                    <p className={css['lead-title']}>
                      <a href="">Featured Artist</a>
                    </p>
                    <p className={css.heading}>
                      <a href="">
                        <strong>Hello World!</strong>
                      </a>
                    </p>
                    <div className={css.description}>
                      <a href="">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua
                        </p>
                      </a>
                    </div>
                  </div>
                  <p className={css.actionbutton}>
                    <a
                      href=""
                      className={`${css.button} ${css['button-white']} ${
                        css['button-animate']
                      } ${css['button-animate-dark']}`}
                    >
                      Category Link
                      <span
                        className={`${css.icon} ${
                          css['c-highlight1']
                        } material-symbols-outlined`}
                      >
                        arrow_right_alt
                      </span>
                    </a>
                  </p>
                </div>
                <div className={css.box}>
                  <a href="" className={`${css.image} ${css['hover-large']}}`}>
                    <img
                      src="/static/images/landing/temp/sample3.jpg"
                      className={css.productpic}
                    />
                  </a>
                  <div className={css.details}>
                    <p className={css['lead-title']}>
                      <a href="">Featured Artist</a>
                    </p>
                    <p className={css.heading}>
                      <a href="">
                        <strong>Hello World!</strong>
                      </a>
                    </p>
                    <div className={css.description}>
                      <a href="">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua
                        </p>
                      </a>
                    </div>
                  </div>
                  <p className={css.actionbutton}>
                    <a
                      href=""
                      className={`${css.button} ${css['button-white']} ${
                        css['button-animate']
                      } ${css['button-animate-dark']}`}
                    >
                      Category Link
                      <span
                        className={`${css.icon} ${
                          css['c-highlight1']
                        } material-symbols-outlined`}
                      >
                        arrow_right_alt
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      <section id={css['category-links']}>
        <div className={css.container}>
          <h5>Other Popular Categories</h5>
          <div className={css.links}>
            <Link 
              to="/c/jewelry/bracelets"
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Bracelets
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/jewelry/earrings" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Earrings
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/jewelry/necklaces" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Necklaces
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/area-rugs" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Area Rugs
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/decor-accessories" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Decor Accessories
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/pillows-and-throws" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Pillows & Throws
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/tableware" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Tableware
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/holiday-decor-and-ornaments" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Holiday Decor & Ornaments
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/lamps-and-lighting" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Lamps & Lighting
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
            <Link 
              to="/c/home-decor/chess-sets-and-games" 
              className={`${css.button} ${css['button-white']} ${css['button-animate']} ${css['fixedwidth']} ${css['button-animate-highlight1']}`}
            >
              Chess Sets & Games
              <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
            </Link>
          </div>
        </div>
      </section>

      {/* highlight1 */}
      {/*<section className={`${css.categoryview} ${css['layout-type5']}`}>
        <div className={css.container}>
          <div className={`${css.container} ${css.full}`}>
            <div className={`${css['cat-grid']} ${css.text}`}>
              <div className={css.box}>
                <p className={css['lead-title']}>
                  <a href="">Trending Now</a>
                </p>
                <h4 className={css.heading}>
                  <a href="">
                    {intl.formatMessage({
                      id: 'HomePageCustom.discoverAllThings',
                    })}
                  </a>
                </h4>
                <p>
                  <a href="">
                    {intl.formatMessage({
                      id: 'HomePageCustom.discoverAllThingsDescription',
                    })}
                  </a>
                </p>
                <p>
                  <a
                    href=""
                    className={`${css.button} ${css['button-white']} ${
                      css['button-animate']
                    } ${css['button-animate-highlight1']}`}
                  >
                    {intl.formatMessage({ id: 'HomePageCustom.viewAll' })}
                    <span
                      className={`${css.icon} ${
                        css['c-highlight1']
                      } material-symbols-outlined`}
                    >
                      arrow_right_alt
                    </span>
                  </a>
                </p>
              </div>
              <div className={css.list}>
                <div className={css.row1}>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/p317515_2_400.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/p260066_2_400.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/temp/sample3.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                </div>
                <div className={css.row2}>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/temp/sample6.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/temp/sample7.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/temp/sample8.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                  <div className={css.box}>
                    <a
                      href=""
                      className={`${css.image} ${css['hover-large']}}`}
                    >
                      <img
                        src="/static/images/landing/p435381_2c_400.jpg"
                        className={css.productpic}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>*/}
      {/*<section id={css['about-handmade']}>
        <div className={css.container}>
          <div className={css.content}>
            <h2>
              {intl.formatMessage({
                id: 'HomePageCustom.multipleColumnsSectionTitle',
              })}
            </h2>
            <div id={css['about-handmade-details']}>
              <div>
                <p className={css.icon}>
                  <span className={`${css.icon} material-symbols-outlined`}>
                    lightbulb
                  </span>
                </p>
                <div>
                  <p className={css.title}>
                    <strong>
                      {intl.formatMessage({
                        id: 'HomePageCustom.multipleColumnsSectionCol1Title',
                      })}
                    </strong>
                  </p>
                  <p>
                    {intl.formatMessage({
                      id:
                        'HomePageCustom.multipleColumnsSectionCol1Description',
                    })}
                  </p>
                </div>
              </div>
              <div>
                <p className={css.icon}>
                  <span className={`${css.icon} material-symbols-outlined`}>
                    handshake
                  </span>
                </p>
                <div>
                  <p className={css.title}>
                    <strong>
                      {intl.formatMessage({
                        id: 'HomePageCustom.multipleColumnsSectionCol2Title',
                      })}
                    </strong>
                  </p>
                  <p>
                    {intl.formatMessage({
                      id:
                        'HomePageCustom.multipleColumnsSectionCol2Description',
                    })}
                  </p>
                </div>
              </div>
              <div>
                <p className={css.icon}>
                  <span className={`${css.icon} material-symbols-outlined`}>
                    box
                  </span>
                </p>
                <div>
                  <p className={css.title}>
                    <strong>
                      {intl.formatMessage({
                        id: 'HomePageCustom.multipleColumnsSectionCol3Title',
                      })}
                    </strong>
                  </p>
                  <p>
                    {intl.formatMessage({
                      id:
                        'HomePageCustom.multipleColumnsSectionCol3Description',
                    })}
                  </p>
                </div>
              </div>
            </div>
            <div className={css.link}>
              <a
                href="/about-us/the-movement/"
                id={css['about-handmade-button1']}
                className={`${css.button} ${css['button-white']} ${
                  css['button-animate']
                } ${css['button-animate-highlight1']}`}
              >
                The Movement
                <span
                  className={`${css.icon} ${
                    css['c-highlight1']
                  } material-symbols-outlined`}
                >
                  arrow_right_alt
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>*/}
    </div>
  );
};

const HomePageCustom = () => {
  const intl = useIntl();
  const config = useConfiguration();

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  const { sellerVerificationInProgress } = useSelector(state => state.auth);

  if (sellerVerificationInProgress) {
    return (
      <Page title={'Loading'} scrollingDisabled={scrollingDisabled}>
        <div className={css.loading}>
          <FormattedMessage id="AuthenticationPage.verifyingSeller" />
          <IconSpinner />
        </div>
      </Page>
    );
  }

  const description = intl.formatMessage({
    id: 'HomePageCustom.schemaDescription',
  });
  const title = intl.formatMessage(
    { id: 'HomePageCustom.schemaTitle' },
    { marketplaceName: config.marketplaceName }
  );
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    description,
    name: title,
  };

  return (
    <Page
      title={title}
      scrollingDisabled={scrollingDisabled}
      referrer="origin"
      schema={pageSchemaForSEO}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <HomePageSection />
      </LayoutSingleColumn>
    </Page>
  );
};

export default HomePageCustom;
