import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import css from './Skeleton.module.css';

const Skeleton = props => {
  const { className } = props;
  return <div className={classNames(css.root, className)}></div>;
};

const SkeletonSection = props => {
  const { numOfSkeletons, className } = props;

  return _.map(new Array(numOfSkeletons), (_, index) => (
    <Skeleton className={className} key={index} />
  ));
};

export default SkeletonSection;
