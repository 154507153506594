import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

// import useSmoothScrollTo from '../../../hooks/useSmoothScrollTo';
import css from '../HomePageCustom.module.css';

const HomepageCategoryGrid = props => {
  const { id, scrollTarget } = props;

  // const scrollTo = useSmoothScrollTo(scrollTarget);

  return (
    <section id={id} className={`${css.categoryview} ${css['layout-type2']}`}>
      <div className={css.container}>
        <div className={`${css.container} ${css.full}`}>
          <div className={css.description}>
            <h4 className={`${css.heading} ${css.nomargin}`}>Trending Now</h4>
          </div>
          <div className={`${css['cat-grid']} ${css.image}`}>
            <div className={css.box}>
              <Link 
                to="/c/jewelry" 
                className={`${css.image} ${css['hover-large']}`}>
                <img src="http://images1.novica.net/pictures/15/p435381_2c_400.jpg" className={css.productpic} />
                <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                  Jewelry
                  <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                </span>
              </Link>
            </div>
            <div className={css.list}>
              <div className={css.box}>
                <Link 
                  to="/c/fashion" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="https://assets3.novica.net/2015/assets/images/categoryplate/fashion/sweaters-2023OCT06.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Handmade Fashion
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
              <div className={css.box}>
                <Link 
                  to="/c/home-decor" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="https://assets3.novica.net/2015/assets/images/categoryplate/home-decor/AreaRugs_2023SEP07.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Home Decor
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
              <div className={css.box}>
                <Link 
                  to="/c/home-decor/sculpture" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="//images1.novica.net/pictures/10/p385455_2_400.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Sculpture
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
              <div className={css.box}>
                <Link 
                  to="/c/home-decor/vases" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="//images1.novica.net/pictures/2/p397431_2_400.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Vases
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
              <div className={css.box}>
                <Link 
                  to="/c/fashion/clothing" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="https://assets3.novica.net/2015/assets/images/categoryplate/20231113_Homepage_GiftsForHim.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Clothing
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
              <div className={css.box}>
                <Link 
                  to="/c/fashion/clothing/ponchos" 
                  className={`${css.image} ${css['hover-large']}`}
                >
                  <img src="https://images1.novica.net/pictures/20/p395043_2_400.jpg" className={css.productpic} />
                  <span className={`${css.button} ${css['button-small']} ${css['button-white']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth}`}>
                    Ponchos
                    <span className={`${css.icon} material-symbols-outlined ${css['c-highlight1']}`}>arrow_right_alt</span>
                  </span>
                </Link>
                {/*<!-- <div className={css.details}>
                  <Link 
                    to="" 
                    className={`${css.button} ${css['button-offwhite']} ${css['button-small']} ${css['button-animate']} ${css['button-animate-dark']} ${css.fixedwidth} ${css.textcentered}`}
                  >
                    Category Link
                  </Link>
                </div> -->*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageCategoryGrid;
