import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import useDragScroll from '../../../hooks/useDragScroll';
import { ListingCard } from '../../../components';
import ListingImageFullScreenGallery from '../../ListingPage/ListingImageFullScreenGallery/ListingImageFullScreenGallery';
import SkeletonSection from '../../../components/Skeleton/Skeleton';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from '../HomePageCustom.module.css';

const { Money } = sdkTypes;

const cardRenderSizes = isMapVariant => {
  if (isMapVariant) {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  } else {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  }
};

const HomePageSliders = () => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  useDragScroll(containerRef);
  const [largePicModalOptions, setLargePicModalOptions] = useState({
    isModalOpen: false,
    imageItems: [],
  });
  const { transactions, fetchTransactionsInProgress } = useSelector(
    state => state.LandingPage
  );

  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));

  const openLargePicModal = imageItems => {
    setLargePicModalOptions({
      isModalOpen: true,
      imageItems,
    });
  };

  const memoList = useMemo(
    () =>
      _.map(_.sampleSize(transactions, 50), (item, index) => {
        const { amount, currency } = item.attributes.price;
        item.attributes.price = new Money(amount, currency);
        return (
          <ListingCard
            className={`${css.listingCard} ${css.box} box`}
            key={item.id.uuid}
            listing={item}
            renderSizes={cardRenderSizes(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            showZoomIcon={true}
            openLargePicModal={openLargePicModal}
          />
        );
      }),
    [transactions]
  );

  return (
    <>
      <div
        ref={containerRef}
        id={css['ideas-productlist']}
        className={`${css.container} ${css.full} ${css.list} ${css.scroll} ${css.slider}`}
      >
        {fetchTransactionsInProgress ? (
          <SkeletonSection
            numOfSkeletons={6}
            className={`${css.skeleton} ${css.box}`}
          />
        ) : (
          <>
            {memoList}
            <ListingImageFullScreenGallery
              imageItems={largePicModalOptions.imageItems}
              isModalOpen={largePicModalOptions.isModalOpen}
              closeModal={() =>
                setLargePicModalOptions({
                  isModalOpen: false,
                  imageItems: [],
                })
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default HomePageSliders;
